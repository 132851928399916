body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply transition-all rounded-lg shadow px-12 py-1 uppercase text-white cursor-pointer;
  }
  .button-side {
    @apply bg-blue-400 hover:bg-blue-500;
  }
  .button-main {
    @apply bg-emerald-400 hover:bg-emerald-500;
  }
}
